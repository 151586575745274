import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MenuSelectionModal from './MenuSelectionModal';

function ProductList({
  categories,
  selectedCategory,
  setSelectedCategory,
  itemsData,
  selectedSize,
  setSelectedSize,
  handleIngredientToggle,
  selectedIngredients,
  availableExtraIngredients,
  handleExtraIngredientToggle,
  addToCart,
  extraIngredients
}) {
  const categoriesWithoutIngredients = ["Boissons", "Desserts", "Pates"];

  return (
    <div>
      <div className="categories sticky-top mb-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`btn me-2 mb-2 ${selectedCategory === category ? "btn-danger active" : "btn-outline-danger"}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="row">
        {itemsData[selectedCategory]?.map((item, index) => (
          <div key={`${selectedCategory}-${index}`} className="col-md-4 d-flex align-content-stretch flex-wrap mb-4">
            <div className="card p-3 text-center w-100">
              <span>{item.name}</span>

               {/* Affichage de la description du menu */}
                {item.description && (
                  <div className="mt-2">
                    <p>{item.description}</p>
                  </div>
                )}

                {/* Affichage des restrictions du menu */}
                {item.restrictions && (
                  <div className="mt-2 text-danger">
                    <small>Restrictions : {item.restrictions}</small>
                  </div>
                )}


              {/* Taille (si applicable) */}
              {item.sizes ? (
                <div className="mt-2">
                  <select
                    value={selectedSize[item.id]?.name || item.sizes[0].name}
                    onChange={(e) =>
                      setSelectedSize((prevSizes) => ({
                        ...prevSizes,
                        [item.id]: item.sizes.find((s) => s.name === e.target.value),
                      }))
                    }
                    className="form-select"
                  >
                    {item.sizes.map((size) => (
                      <option key={size.name} value={size.name}>
                        {size.name} - {size.price} €
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="mt-2">Prix : {item.basePrice || item.price} €</div>
              )}

              {/* Ingrédients */}
              {!categoriesWithoutIngredients.includes(selectedCategory) && item.ingredients && (
                <div className="mt-3">
                  <h5>Ingrédients :</h5>
                  <div className="overflow-auto" style={{ maxHeight: "100px" }}>
                    {item.ingredients.map((ingredient) => (
                      <div key={ingredient} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            selectedIngredients[item.id]
                              ? selectedIngredients[item.id].includes(ingredient)
                              : true
                          }
                          onChange={() => handleIngredientToggle(item.id, ingredient)}
                        />
                        <label className="form-check-label">{ingredient}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Ingrédients supplémentaires */}
              {selectedCategory === "Pizzas" && availableExtraIngredients.length > 0 && (
                <div className="mt-3">
                  <h5>Ingrédients supplémentaires :</h5>
                  <div className="overflow-auto" style={{ maxHeight: "100px" }}>
                    {availableExtraIngredients.map((extra) => (
                      <div key={extra.name} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={extraIngredients[item.id]?.includes(extra) ?? false}
                          onChange={() => handleExtraIngredientToggle(item.id, extra)}
                        />
                        <label className="form-check-label">
                          {extra.name} - {extra.price} €
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <button onClick={() => addToCart(item)} className="btn btn-primary mt-3">
                Ajouter au panier
              </button>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

function App() {
  const [cart, setCart] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState('À Emporter');
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Pizzas');
  const [itemsData, setItemsData] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedIngredients, setSelectedIngredients] = useState({});
  const [extraIngredients, setExtraIngredients] = useState({});
  const [availableExtraIngredients, setAvailableExtraIngredients] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({ name: '', email: '', phone: '' });
  const [deliveryInfo, setDeliveryInfo] = useState({
    address: '',
    postalCode: '',
    complement: '',
    phone: '',
    email: '',
    deliveryTime: '',
  });
  const [errors, setErrors] = useState({});

  // Fetch product data from JSON files
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categories = ['pizzas', 'burgers', 'pates', 'salades', 'paninis', 'snacks', 'desserts', 'boissons'];
        const data = {};
  
        for (const category of categories) {
          const response = await fetch(`react/produitsMenu/${category}.json`);
          if (!response.ok) {
            throw new Error(`Erreur lors du chargement des produits pour la catégorie ${category}`);
          }
          data[category.charAt(0).toUpperCase() + category.slice(1)] = await response.json();
        }
  
        setItemsData(data); // Initialisation des données pour toutes les catégories
      } catch (error) {
        console.error('Erreur lors du chargement des produits :', error);
      }
    };
  
    fetchData();
  }, []); // Charge les produits une seule fois lors du montage du composant
  

  useEffect(() => {
    const fetchExtraIngredients = async () => {
      try {
        const response = await fetch(`react/produitsMenu/ingredients.json`);
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des ingrédients supplémentaires');
        }
        const data = await response.json();
        setAvailableExtraIngredients(data);
      } catch (error) {
        console.error('Erreur lors du chargement des ingrédients supplémentaires :', error);
      }
    };
    fetchExtraIngredients();
  }, []);



  

  const applyPizzaOffer = (cart) => {
    // Filtrer seulement les pizzas dans le panier
    const pizzas = cart
      .map((item, index) => ({ ...item, index }))
      .filter((item) => item.category === "Pizzas");
  
    // Appliquer l'offre pour chaque groupe de 3 pizzas
    let updatedCart = [...cart];
    for (let i = 0; i < Math.floor(pizzas.length / 3); i++) {
      // Sélectionner les 3 pizzas du groupe
      const pizzaGroup = pizzas.slice(i * 3, i * 3 + 3);
  
      // Trouver la pizza la moins chère dans ce groupe
      const cheapestPizza = pizzaGroup.reduce((cheapest, pizza) =>
        pizza.finalPrice < cheapest.finalPrice ? pizza : cheapest
      );
  
      // Appliquer la gratuité à cette pizza
      const pizzaIndex = cheapestPizza.index;
      updatedCart[pizzaIndex] = {
        ...updatedCart[pizzaIndex],
        finalPrice: 0, // Marquer la pizza comme offerte
        isFree: true,  // Indicateur de gratuité
      };
    }
  
    return updatedCart;
  };
  
  const addToCart = (item) => {
    const ingredients = item.ingredients ? (selectedIngredients[item.id] || [...item.ingredients]) : []; 
    const extraIngredientsPrice = (extraIngredients[item.id] || []).reduce(
      (total, extra) => total + extra.price,
      0
    );
  
    const basePrice = selectedSize[item.id]?.price || item.basePrice || item.price;
    const itemPrice = basePrice + extraIngredientsPrice;
  
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (cartItem) =>
          cartItem.id === item.id &&
          cartItem.size?.name === selectedSize[item.id]?.name &&
          JSON.stringify(cartItem.selectedIngredients) === JSON.stringify(ingredients) &&
          JSON.stringify(cartItem.extraIngredients) === JSON.stringify(extraIngredients[item.id])
      );
  
      let updatedCart;
  
      if (existingItemIndex >= 0) {
        // Si le produit existe déjà, augmenter la quantité
        updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += 1;
        updatedCart[existingItemIndex].finalPrice += itemPrice;
      } else {
        // Sinon, ajouter le nouvel élément au panier
        updatedCart = [
          ...prevCart,
          {
            ...item,
            category: selectedCategory, // Assurez-vous que chaque item a une catégorie
            quantity: 1,
            finalPrice: itemPrice,
            size: selectedSize[item.id],
            selectedIngredients: ingredients,
            extraIngredients: extraIngredients[item.id] || [],
          },
        ];
      }
  
      // Appliquer l'offre automatiquement
      return applyPizzaOffer(updatedCart);
    });
  };
  
  
  
  
 
  
  
  
  

  const updateCartQuantity = (itemId, sizeName, newQuantity) => {
    setCart((prevCart) => {
      return prevCart.map((item) => {
        if (item.id === itemId && item.size?.name === sizeName) {
          const extraIngredientsPrice = item.extraIngredients.reduce(
            (total, extra) => total + extra.price,
            0
          );
          const basePrice = item.size?.price || item.basePrice || item.price;
          return {
            ...item,
            quantity: newQuantity,
            finalPrice: (basePrice + extraIngredientsPrice) * newQuantity,
          };
        }
        return item;
      });
    });
  };
  

  const clearCart = () => {
    setCart([]);
  };

  const removeFromCart = (itemToRemove) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) =>
          item.id === itemToRemove.id &&
          item.size?.name === itemToRemove.size?.name &&
          JSON.stringify(item.selectedIngredients) === JSON.stringify(itemToRemove.selectedIngredients) &&
          JSON.stringify(item.extraIngredients) === JSON.stringify(itemToRemove.extraIngredients)
      );
  
      if (existingItemIndex >= 0) {
        const updatedCart = [...prevCart];
        if (updatedCart[existingItemIndex].quantity > 1) {
          // Réduire la quantité de 1 si elle est supérieure à 1
          updatedCart[existingItemIndex].quantity -= 1;
          updatedCart[existingItemIndex].finalPrice -=
            updatedCart[existingItemIndex].finalPrice / (updatedCart[existingItemIndex].quantity + 1);
        } else {
          // Sinon, retirer complètement l'élément du panier
          updatedCart.splice(existingItemIndex, 1);
        }
        return updatedCart;
      }
  
      return prevCart;
    });
  };
  
  
  
  

  const handleIngredientToggle = (itemId, ingredient) => {
    setSelectedIngredients((prevIngredients) => {
      const updatedIngredients = prevIngredients[itemId] ? [...prevIngredients[itemId]] : [...itemsData[selectedCategory].find(item => item.id === itemId).ingredients];
      if (updatedIngredients.includes(ingredient)) {
        return {
          ...prevIngredients,
          [itemId]: updatedIngredients.filter((ing) => ing !== ingredient),
        };
      }
      updatedIngredients.push(ingredient);
      return {
        ...prevIngredients,
        [itemId]: updatedIngredients,
      };
    });
  };
  

  const handleExtraIngredientToggle = (itemId, extra) => {
    setExtraIngredients((prevExtras) => {
      const updatedExtras = prevExtras[itemId] ? [...prevExtras[itemId]] : [];
      const isIncluded = updatedExtras.find(e => e.name === extra.name);
      if (isIncluded) {
        return {
          ...prevExtras,
          [itemId]: updatedExtras.filter((e) => e.name !== extra.name),
        };
      }
      updatedExtras.push(extra);
      return {
        ...prevExtras,
        [itemId]: updatedExtras,
      };
    });
  };
  

  const handleShowDeliveryModal = () => setShowDeliveryModal(true);
  const handleCloseDeliveryModal = () => setShowDeliveryModal(false);

  const handleSubmitOrder = () => {
    if (cart.length === 0) {
      alert("Votre panier est vide. Veuillez ajouter des articles avant de passer la commande.");
      return;
    }

    if (deliveryMethod === 'Livraison') {
      handleShowDeliveryModal();
    } else {
      submitOrder('pickup');
    }
  };

  const handleCustomerInfoChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleDeliveryInfoChange = (e) => {
    const { name, value } = e.target;
    setDeliveryInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  // const submitOrder = (orderType, deliveryInfo = null) => {
  //   const formData = new URLSearchParams();
  //   cart.forEach((item, index) => {
  //     formData.append(`cart[${index}][name]`, item.name);
  //     formData.append(`cart[${index}][finalPrice]`, item.finalPrice);
  //     formData.append(`cart[${index}][quantity]`, item.quantity);
  //     if (item.size) {
  //       formData.append(`cart[${index}][size]`, item.size.name);
  //     }
  //     if (item.selectedIngredients) {
  //       item.selectedIngredients.forEach((ingredient, i) => {
  //         formData.append(`cart[${index}][ingredients][${i}]`, ingredient);
  //       });
  //     }
  //     if (item.extraIngredients) {
  //       item.extraIngredients.forEach((extra, i) => {
  //         formData.append(`cart[${index}][extraIngredients][${i}]`, extra.name);
  //       });
  //     }
  //   });

  //   formData.append('deliveryMethod', deliveryMethod);
  //   formData.append('orderType', orderType);
  //   formData.append('customerName', customerInfo.name);
  //   formData.append('customerEmail', customerInfo.email);
  //   formData.append('customerPhone', customerInfo.phone);
  //   if (deliveryInfo) {
  //     formData.append('deliveryInfo', JSON.stringify(deliveryInfo));
  //   }

  //   fetch('pages/valider_commande.php', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: formData.toString(),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         return Promise.reject('Erreur lors de la soumission');
  //       }
  //       return response.json();
  //     })
  //     .then((result) => {
  //       window.location.href = `index.php?page=recapitulatif&token=${result.token}`;
  //     })
  //     .catch((error) => {
  //       console.error('Erreur lors de la soumission:', error);
  //       alert('Erreur lors de la soumission. Veuillez réessayer.');
  //     });
  // };

  const submitOrder = (orderType, deliveryInfo = null) => {
    // Step 1: Check if the user is logged in
    fetch('pages/check_session.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(sessionStatus => {
        // If user is not logged in, redirect to the login page
        if (!sessionStatus.loggedIn) {
          window.location.href = sessionStatus.redirectUrl;
          return;
        }
  
        // Step 2: If user is logged in, continue to submit the order
        const formData = new URLSearchParams();
        cart.forEach((item, index) => {
          formData.append(`cart[${index}][name]`, item.name);
          formData.append(`cart[${index}][finalPrice]`, item.finalPrice);
          formData.append(`cart[${index}][quantity]`, item.quantity);
          if (item.size) {
            formData.append(`cart[${index}][size]`, item.size.name);
          }
          if (item.selectedIngredients) {
            item.selectedIngredients.forEach((ingredient, i) => {
              formData.append(`cart[${index}][ingredients][${i}]`, ingredient);
            });
          }
          if (item.extraIngredients) {
            item.extraIngredients.forEach((extra, i) => {
              formData.append(`cart[${index}][extraIngredients][${i}]`, extra.name);
            });
          }
        });
  
        formData.append('deliveryMethod', deliveryMethod);
        formData.append('orderType', orderType);
        formData.append('customerName', customerInfo.name);
        formData.append('customerEmail', customerInfo.email);
        formData.append('customerPhone', customerInfo.phone);
        if (deliveryInfo) {
          formData.append('deliveryInfo', JSON.stringify(deliveryInfo));
        }
  
        // Step 3: Submit the order to valider_commande.php
        return fetch('pages/valider_commande.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData.toString(),
        });
      })
      .then(response => {
        if (!response.ok) {
          return Promise.reject('Erreur lors de la soumission');
        }
        return response.json();
      })
      .then(result => {
        window.location.href = `index.php?page=recapitulatif&token=${result.token}`;
      })
      .catch(error => {
        console.error('Erreur lors de la soumission:', error);
        alert('Veuillez vous connecter pour passer une commande.');
      });
  };
  
  const validatePhone = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(deliveryInfo.phone)) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: 'Le numéro de téléphone doit comporter 10 chiffres.' }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, phone: null }));
    return true;
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(deliveryInfo.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Veuillez entrer une adresse email valide.' }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, email: null }));
    return true;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <ProductList
            categories={Object.keys(itemsData)}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            itemsData={itemsData}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            handleIngredientToggle={handleIngredientToggle}
            selectedIngredients={selectedIngredients}
            availableExtraIngredients={availableExtraIngredients}
            handleExtraIngredientToggle={handleExtraIngredientToggle}
            addToCart={addToCart}
            extraIngredients={extraIngredients}
          />
        </div>
        <div className="col-md-4">
        <div className="cart sticky-top p-3 bg-light rounded">
          <h2>Panier</h2>
          <div className="mb-3">
            <strong>Choix de livraison : {deliveryMethod}</strong>
          </div>
          {cart.length > 0 ? (
            <>
              {cart.map((item, index) => (
                <div key={index} className="cart-item d-flex justify-content-between mb-2">
                  <span>
                    {item.name} ({item.size?.name || "Standard"}) - 
                    {item.isFree ? "Gratuit" : `${item.finalPrice.toFixed(2)} €`} x{item.quantity}
                    <br />
                    {item.selectedIngredients && item.selectedIngredients.length > 0 && (
                      <small>Ingrédients: {item.selectedIngredients.join(", ")}</small>
                    )}
                    {item.extraIngredients && item.extraIngredients.length > 0 && (
                      <>
                        <br />
                        <small>Ingrédients supplémentaires: {item.extraIngredients.map((extra) => extra.name).join(", ")}</small>
                      </>
                    )}
                  </span>
                  <button onClick={() => removeFromCart(item)} className="btn btn-sm btn-danger">
                    Retirer
                  </button>
                </div>
              ))}





              <div className="mt-3">
                <button
                  className={`btn ${deliveryMethod === "Livraison" ? "btn-primary" : "btn-outline-primary"} me-2`}
                  onClick={() => setDeliveryMethod("Livraison")}
                >
                  En Livraison
                </button>
                <button
                  className={`btn ${deliveryMethod === "À Emporter" ? "btn-primary" : "btn-outline-primary"}`}
                  onClick={() => setDeliveryMethod("À Emporter")}
                >
                  À Emporter
                </button>
              </div>

              <div className="row mt-4">
                <div className="col-6">
                  <button onClick={clearCart} className="btn btn-danger w-100">
                    Retirer tout
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-success w-100" onClick={handleSubmitOrder}>
                    Commander
                  </button>
                </div>
              </div>

              <div className="total mt-4">
                <strong>Total: {cart.reduce((total, item) => total + item.finalPrice * item.quantity, 0)} €</strong>
              </div>
            </>
          ) : (
            <p>Votre panier est vide.</p>
          )}
        </div>

        </div>
      </div>

      <Modal show={showDeliveryModal} onHide={handleCloseDeliveryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Informations de livraison</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {deliveryMethod === 'Livraison' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Adresse complète</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={deliveryInfo.address}
                    onChange={handleDeliveryInfoChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Code postal</Form.Label>
                  <Form.Control
                    as="select"
                    name="postalCode"
                    value={deliveryInfo.postalCode}
                    onChange={handleDeliveryInfoChange}
                    required
                  >
                    <option value="" disabled>Choisissez un code postal</option>
                    <option value="93170">Bagnolet (93170)</option>
                    <option value="93260">Les Lilas (93260)</option>
                    <option value="93100">Montreuil (93100)</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Complément d'adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="complement"
                    value={deliveryInfo.complement}
                    onChange={handleDeliveryInfoChange}
                  />
                </Form.Group>
              </>
            )}

<Form.Group className="mb-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={deliveryInfo.name}
                onChange={handleDeliveryInfoChange}
                required
              />
              
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={deliveryInfo.phone}
                onChange={handleDeliveryInfoChange}
                required
              />
              {errors.phone && <small className="text-danger">{errors.phone}</small>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={deliveryInfo.email}
                onChange={handleDeliveryInfoChange}
                required
              />
              {errors.email && <small className="text-danger">{errors.email}</small>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeliveryModal}>
            Fermer
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (validatePhone() && validateEmail()) {
                setShowDeliveryModal(false);
                submitOrder('delivery', deliveryInfo);
              }
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;